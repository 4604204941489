/* eslint-disable import/no-anonymous-default-export */

import menu from '../assets/menu.jpg';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.jpg';

import perla from '../assets/logo1.png';
import spelBg from '../assets/Biljard-bg.jpg';
import biljard from '../assets/BILJARD.jpg';
import grill from '../assets/grillMenu-bg.jpg';
import lunnchMeny from '../assets/lunchMenu-bg.jpg';
import kontakt from '../assets/kontakt.jpg';
import grillMenu from '../assets/menu-grill.jpg';
import erbjudande from '../assets/Erbjudande.jpg';
import pizza from '../assets/menu2.jpg';
import event from '../assets/event.jpg';
import eventMusic from '../assets/music.jpg';


export default {
 
  menu,
  spoon,
  welcome,
  perla,
  spelBg,
  biljard,
  grill,
  lunnchMeny,
  kontakt,
  grillMenu,
  erbjudande,
  pizza,
  event,
  eventMusic,
  

};
